<template>
  <div class="sidebar">
    <div class="title">
      <span>{{ title }}</span>
    </div>
    <div
      v-loading="loading"
      class="sidebar-articles"
    >
      <el-row>
        <el-col
          v-for="feedItem in feedItems"
          :key="feedItem.id"
          class="el-col-sidebar-articles"
        >
          <router-link
            v-if="feedItem.feed_key === 'Article'"
            :to="{ name: isPublic ? 'public-article' : 'feed-article', params: { id: feedItem.id } }"
          >
            <article-card
              :media="feedItem.mediaFile.is_image? feedItem.mediaFile.large_thumb: feedItem.mediaFile.video_thumb"
              :is-image="feedItem.mediaFile.is_image"
              :title="feedItem.title"
              :author-name="feedItem.author"
              :author-avatar="feedItem.author_avatar_url"
              :date="feedItem.published_at"
              :featured="feedItem.is_featured"
            />
          </router-link>
          <router-link
            v-if="feedItem.feed_key === 'Event'"
            :to="{ name: isPublic ? 'public-event' : 'feed-event', params: { id: feedItem.id } }"
          >
            <event-card
              :title="feedItem.title"
              :featured="feedItem.is_featured"
              :venue="feedItem.address_line_1"
              :start-date="feedItem.start_date"
              :end-date="feedItem.end_date"
              :start-time="feedItem.start_time"
              :end-time="feedItem.end_time"
              :same-day="feedItem.same_day"
            />
          </router-link>
          <router-link
            v-if="feedItem.feed_key === 'Reward'"
            :to="{ name: isPublic ? 'public-reward' : 'feed-reward', params: { id: feedItem.id } }"
          >
            <offer-card
              :title="feedItem.title"
              :media="feedItem.mediaFile.is_image ? feedItem.mediaFile.large_thumb : feedItem.mediaFile.video_thumb"
              :is-image="feedItem.mediaFile.is_image"
            />
          </router-link>
        </el-col>
      </el-row>
    </div>
    <div
      v-if="tags.length"
      class="tags"
    >
      <div class="title">
        <span>tags</span>
      </div>
      <div class="list">
        <div
          v-for="tag in tags"
          :key="tag.id"
          class="tag"
        >
          <span>{{ tag.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import feedApi from '@/modules/feed/services/feed.api'
import ArticleCard from '@/modules/feed/components/article-card'
import EventCard from '@/modules/feed/components/event-card'
import OfferCard from '@/modules/feed/components/offer-card'

export default {
  components: {
    ArticleCard,
    EventCard,
    OfferCard,
  },

  props: {
    feedKey: {
      type: String,
      default: '',
    },
    tags: {
      type: Array,
      default: () => [],
    },
    isRelated: {
      type: Boolean,
      default: false,
    },
    isPublic: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    loading: false,
    feedItems: []
  }),

  computed: {
    title() {
      if (!this.isRelated) {
        return 'Latest articles & events'        
      }

      if (this.feedKey == 'Reward') {
        return 'Related offers'
      }

      return 'Related articles & events'
    },
    feedApiMethod() {
      if (this.isPublic && this.isRelated) {
        return 'publicFeedRelated'
      } else if (this.isPublic && !this.isRelated) {
        return 'publicFeed'
      } else if (!this.isPublic && this.isRelated) {
        return 'feedRelated'
      } else {
        return 'feed'
      }
    },
    itemType() {
      return 'Modules\\Article\\Entities\\Article, Modules\\Event\\Entities\\Event'
    }
  },

  watch: {
    '$route.params.id' (id) {
      this.feedItems = []
      this.fetchSideBarItems()
    }
  },

  created() {
    this.fetchSideBarItems()
  },

  methods: {
    fetchSideBarItems() {
      this.loading = true

      this.resolveFeedRequest()
        .then((response) => {
          this.loading = false
          this.feedItems = response.data.data
        })
    },
    resolveFeedRequest () {
      if (this.isRelated) {
        return feedApi.feedRelated(this.isPublic, {
          item_id: this.$route.params.id,
          item_type: `Modules\\${this.feedKey}\\Entities\\${this.feedKey}`,
          take: 3
        })
      }

      return feedApi.feed(this.isPublic, {
        type: `Modules\\${this.feedKey}\\Entities\\${this.feedKey}`,
        page: 1,
        per_page: 3,
        order_by: 'published_at',
        order: 'desc'
      })
    },
  },
}
</script>

<style lang="scss">
.sidebar {
  padding: 31px 0 31px 31px;
  text-align: left;
  .sidebar-articles {
    min-height: 100px;
    .el-col-sidebar-articles {
      margin-bottom: 30px;
    }
  }
  .title {
    font-size: 12px;
    font-weight: bold;
  }
  .tags {
    margin-top: 34px;
    .title {
      margin-bottom: 4px;
    }
    .list {
      width: 87%;
      .tag {
        padding: 5px 17px;
        border: 1px solid $primary-grey-color;
        border-radius: 16px;
        color: $primary-grey-color;
        font-size: 12px;
        text-align: center;
        display: inline-block;
        margin: 8px 8px 0 0;
      }
    }
  }
}
</style>
