<!-- How works Component -->

<template>
  <div class="how-works">
    <div class="container">
      <el-row :gutter="0">
        <el-col
          :xl="17"
          :lg="17"
          :md="24"
          :sm="24"
          :xs="24"
        >
          <div class="left-side">
            <div class="title">
              <h1>HOW TO EARN POINTS</h1>
            </div>
            <div class="desc">
              <!-- <h2>Earn points for your activity within Get Active Hub</h2> -->

              <p>Download Get Active Hub mobile app and you could soon be earning points and unlocking fabulous offers.</p>
              <p>Engaging with our content on the app such as reading and rating an article, taking part in a poll, joining and completing challenges and sharing Get Active Hub with a colleague will all earn you points.</p>
              <p>You can earn the maximum 5 points each time you log a walk, run, ride, swim, workout, sport, mindfulness, or volunteer activity.</p>
              <p>So next time you go for a walk, log your activity on Get Active Hub and start earning points.</p>

              <h2>Points Table</h2>

              <div class="points">
                <p class="point-title">
                  1 Point
                </p>
                <ul>
                  <li><span>- Read an article</span></li>
                  <li><span>- Like an article</span></li>
                  <li><span>- Answer a poll</span></li>
                  <li><span>- Log a photo</span></li>
                  <li><span>- Log a nutrition photo</span></li>
                  <li><span>- Complete your profile</span></li>
                  <li><span>- Upload an Avatar</span></li>
                </ul>
              </div>
              <div class="points">
                <p class="point-title">
                  3 Points
                </p>
                <ul>
                  <li><span>- Join Get Active Hub</span></li>
                  <li><span>- Download the app</span></li>
                  <li><span>- Share Get Active Hub with a colleague</span></li>
                </ul>
              </div>
              <div class="points">
                <p class="point-title">
                  5 Points
                </p>
                <ul>
                  <li><span>- Log an activity</span></li>
                  <li><span>- Walk</span></li>
                  <li><span>- Run</span></li>
                  <li><span>- Ride</span></li>
                  <li><span>- Swim</span></li>
                  <li><span>- Workout</span></li>
                  <li><span>- Sport</span></li>
                  <li><span>- Mindfulness</span></li>
                  <li><span>- Log a volunteer activity</span></li>
                </ul>
              </div>

              <p>Points for joining and completing challenges are assigned per challenge.</p>

              <h2>Automatically record your activity with Strava</h2>
              <p>Sync your Strava account to your profile in Get Active Hub.</p>
              <p>All your rides, runs and walks that you record in Strava will automatically log with Get Active Hub, increasing your points score effortlessly.</p>

              <h2>Offers</h2>
              <p>The points you earn unlock fabulous offers.</p>
            </div>
          </div>
        </el-col>
        <el-col
          :xl="7"
          :lg="7"
          class="hidden-md-and-down"
        >
          <sidebar
            :is-related="false"
            :is-public="true"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/modules/feed/components/sidebar'

export default {
  components: {
    Sidebar
  },

  created() {
    this.$router.push('/login')
  }
}
</script>

<style lang="scss">
.how-works {
  @media (max-width: 991px) {
    overflow: hidden;
  }
  section {
    background-color: #DB0011;
    padding: 35px 0;
    h1 {
      margin: 0;
    }
  }
  .left-side {
    position: relative;
    background-color: $white-color;
    padding: 0 65px 42px 0;
    &:before {
      content: "";
      position: absolute;
      background-color: #fff;
      left: -90%;
      width: 90%;
      height: 100%;
      @media (max-width: 991px) {
        content: "";
        position: absolute;
        background-color: #fff;
        left: -20%;
        width: 140%;
        height: 100%;
        z-index: -1;
      }
    }
    @media (max-width: 991px) {
      padding: 0;
    }
    .title {
      //border-top: 1px solid;
      border-bottom: 1px solid;
      border-color: #EDECED;
      h1 {
        margin-top: 0;
        padding-top: 30px;
        @media (max-width: 575px) {
          font-size: 29px;
          line-height: 40px;
        }
      }
    }
    .desc {
      margin: 20px 0 50px 0;
      p {
        margin: 0 auto 30px auto;
        &.point-title {
          margin: 0 auto 5px auto;
        }
      }
      .points {
        margin-bottom: 30px;
        ul {
          margin: 0;
          padding: 0;
          list-style: none;
        }
      }
    }
  }
}
</style>
